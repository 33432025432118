import React from 'react';
import { useParams } from 'react-router-dom';

import Event from './Event';
import eventHandler from './../../services/events/show';

import Page from '../../components/organisms/Page';
import LoadingSpinner from './../../components/molecules/LoadingSpinner';

function EventWrapper() {
  const params = useParams();
  const response = eventHandler({ params })

  if (response.status === 'success')
    return (
      <Page className='bg-primary' hasOpacityTransition>
        <Event event={response.data} />
      </Page>
    )

  if (response.status === 'error')
    throw Error

  if (response.status === 'loading')
    return <Page className='bg-primary'>
      <LoadingSpinner show />
    </Page>

  return null
}

export default EventWrapper;