import React from 'react';
import OgmiLogo from './../../assets/svgs/logos/ogmi.svg';

export default function RoundTransition({ title, currentRound, totalRounds }) {
  return (
    <div className={`flex flex-1 flex-col bg-black`}>
      <div className='flex flex-1 flex-col items-center justify-center mb-12'>
        <img src={OgmiLogo} className="w-16 mx-auto" />
        <div className='flex flex-col px-4 justify-center'>
          <h2 className='text-2xl font-bold text-center text-white my-12'>{title}</h2>
          <h2 className='leading-none text-80 font-bold text-center text-white'>{currentRound}</h2>
          <h2 className='leading-none text-50 font-bold text-center text-white'>Round</h2>
          <h2 className='text-md font-bold text-center text-gray-400 '>{currentRound} of {totalRounds}</h2>
        </div>
      </div>
    </div >
  );
}
