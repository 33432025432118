import React from "react";

function PrivacyPolicy() {
  return (
    <div className="overflow-y-auto bg-primary text-gray-300 h-full w-full no-scrollbar">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-4">Ogmi Privacy Policy</h1>
        <p className="mb-4">At Ogmi, we take your privacy seriously. This policy explains how we collect, use, and protect your personal information when you use our website and services.</p>

        <h2 className="text-2xl font-bold mb-4">What Information We Collect</h2>

        <p className="mb-4">We collect the following types of personal information:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Name and contact information such as email address, phone number, and mailing address.</li>
          <li>Login credentials such as username and password.</li>
          <li>Payment information such as credit card details.</li>
          <li>Usage information such as browser type, device type, and pages visited.</li>
          <li>Feedback and support information such as user surveys and support tickets.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>

        <p className="mb-4">We use your personal information to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Provide and maintain our services.</li>
          <li>Process payments and prevent fraud.</li>
          <li>Communicate with you about our services and promotions.</li>
          <li>Improve our website and services.</li>
          <li>Provide customer support and respond to inquiries.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">How We Protect Your Information</h2>

        <p className="mb-4">We use industry-standard security measures to protect your personal information from unauthorized access, modification, or disclosure. These measures include:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Encryption of sensitive data using SSL technology.</li>
          <li>Secure storage of data in our servers with limited access controls.</li>
          <li>Regular monitoring of our systems for vulnerabilities and threats.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">Your Rights</h2>

        <p className="mb-4">You have the right to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Access and receive a copy of your personal information.</li>
          <li>Request correction or deletion of your personal information.</li>
          <li>Object to processing of your personal information.</li>
          <li>Withdraw your consent to our use of your personal information.</li>
          <li>File a complaint with a supervisory authority.</li>
        </ul>

        <h2 className="text-2xl font-bold mt-10 mb-4">Data Protection Officer</h2>
        <p className="mb-4">We have appointed a Data Protection Officer (“DPO”) who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact the DPO using the details set out below:</p>
        <p className="mb-4 font-bold">Subject: DPO - [Your name] - [Your Subject]</p>
        <p className="mb-4 font-bold">Email address: support@ogmi.com</p>
        <h2 className="text-2xl font-bold mt-10 mb-4">Changes to this Privacy Policy</h2>
        <p className="mb-4">We reserve the right to update this privacy policy at any time, and we will provide you with a new privacy policy when we make any substantial updates. We may also notify you in other ways from time to time about the processing of your personal data.</p>
        <h2 className="text-2xl font-bold mt-10 mb-4">Contact Us</h2>
        <p className="mb-4">If you have any questions or concerns about our privacy practices or this privacy policy, please contact us at:</p>
        <p className="mb-4 font-bold">Name: Ogmi Customer Support</p>
        <p className="mb-4 font-bold">Email address: support@ogmi.com</p>
        <p className="mt-8">Last updated: 10/04/2023</p>

      </div>
    </div>
  )
}

export default PrivacyPolicy;