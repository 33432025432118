import { useMutation } from 'react-query';
import api from '../../../config/api';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IAnswer, IOpponent, IOpponentRound, IRound, IUser } from '../../../types/api';
import { isCorrectCheck, isWrongCheck } from '../../../helpers/helpers';

interface IParams {
  c_id?: string;
  a_id?: string;
}

interface IUseMatchProps {
  challengeId: string;
}
interface IUseMatchReturn {
  correctAnswer: string | null;
  answerStatus: 'pending' | string;
  selectedAnswer: string | null;
  setSelectedAnswer: Dispatch<SetStateAction<string | null>>;
  round: IRound;
  answers: IAnswer[];
  challenger: IUser;
  challengerRound: IRound;
  selectedAnswerByChallenger: null | string;
  playOrFetchRound: ({
    challengeId,
    answerId,
  }: {
    challengeId?: string;
    answerId?: string;
  }) => void;
  opponent: {
    data: IOpponent | null;
    round: IOpponentRound | null;
    score: number | null;
  };
  setAnsweredIn: Dispatch<SetStateAction<number | null>>;
  answeredIn: number | null;
  setTimer: Dispatch<SetStateAction<number | null>>;
  setInitCountDown: Dispatch<SetStateAction<boolean>>;
  timer: number | null;
  initCountDown: boolean;
  opponentScore: number | null;
}

export const useMatch = ({ challengeId }: IUseMatchProps): IUseMatchReturn => {
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [answerStatus, setAnswerStatus] = useState('pending');
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [round, setRound] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [challenger, setChallenger] = useState(null);
  const [challengerRound, setChallengerRound] = useState(null);
  const [selectedAnswerByChallenger, setSelectedAnswerByChallenger] = useState(null);
  const [answeredIn, setAnsweredIn] = useState(null);
  const [timer, setTimer] = useState<number>(null);
  const [initCountDown, setInitCountDown] = useState(false);
  const [opponent, setOpponent] = useState({
    data: null,
    round: null,
    score: null,
  });
  const [opponentScore, setOpponentScore] = useState(0);

  //set timeout for calling setCTimer
  useEffect(() => {
    const timerTimeout = setTimeout(() => {
      setInitCountDown(true);
    }, 5000);
    return () => clearTimeout(timerTimeout);
  }, [initCountDown]);

  useEffect(() => {
    const timerTimeout = setTimeout(() => {
      if (opponent.round?.answered_in) setOpponentScore(opponent.score);
    }, opponent.round?.answered_in);
    return () => clearTimeout(timerTimeout);
  }, [opponent.score]);

  const nextRound = (result) => {
    const response = result.data;
    setCorrectAnswer(null);
    setSelectedAnswer(null);
    setAnsweredIn(null);
    setRound(response.round || {});
    setAnswers(response.round?.question?.answers);
    setChallenger(response.opponent);
    setChallengerRound(response.opponent_round);
    setAnswerStatus('pending');
    setOpponent({
      data: response.opponent,
      round: response.opponent_round,
      score: response.opponent_total_score,
    });
    setInitCountDown(false);
    if (selectedAnswerByChallenger) setSelectedAnswerByChallenger(null);

    // In seconds
    const challengerAnsweredIn = response?.opponent_round?.answered_in;

    if (challengerAnsweredIn) {
      const challengerAnswerId = response?.opponent_round?.answer_id;

      setTimeout(() => {
        setSelectedAnswerByChallenger(challengerAnswerId);
      }, Math.abs(challengerAnsweredIn) * 1000 + 2000);
    }
  };

  const { mutateAsync: playOrFetchRound } = useMutation(
    ['playChallenge', challengeId],
    async ({ challengeId, answerId }: { challengeId?: string; answerId?: string }) => {
      const params: IParams = {};

      if (challengeId) params['c_id'] = challengeId;

      if (answerId) params['a_id'] = answerId;

      const response = await api.play({
        params: params,
        onSuccess: (response) => {
          if (response.data.code === 'challenge completed') {
            return setTimeout(() => {
              location.replace(
                document.location.pathname.replace(
                  'match',
                  `${response?.data?.game_mode === 'pvp' ? 'reward' : 'result'}`
                )
              );
            }, 2000);
          }

          if (response.data.code === 'round finished') {
            const correctAnswer = response.data?.correct_answer;

            //call endpoint with challengeId
            playOrFetchRound({ challengeId });

            setCorrectAnswer(correctAnswer);

            const isCorrect = isCorrectCheck(correctAnswer, answerId);
            const isWrong = isWrongCheck(correctAnswer, answerId);

            if (isWrong) setAnswerStatus('error');
            if (isCorrect) setAnswerStatus('success');

            return;
          }
          const firstRound = response.data?.round?.current_round === 1;
          const opponentRoundAnswer = opponent.round?.answered_in;

          // if (firstRound) {
          //   nextRound(response);
          // } else {
          //   setTimeout(() => {
          //     nextRound(response);
          //   }, 2000);
          // }

          if (firstRound) {
            nextRound(response);
            return;
          }
          if (!firstRound && !opponentRoundAnswer) {
            setTimeout(() => {
              nextRound(response);
            }, 2000);
            return;
          }
          if (!firstRound && opponentRoundAnswer) {
            if (answeredIn && answeredIn < opponentRoundAnswer) {
              setTimeout(() => {
                nextRound(response);
              }, Math.abs(opponentRoundAnswer - answeredIn + 2) * 1000);
              return;
            } else {
              setTimeout(() => {
                nextRound(response);
              }, 2000);
              return;
            }
          }
        },
      });
      return response?.data;
    }
  );

  return {
    correctAnswer,
    answerStatus,
    setSelectedAnswer,
    selectedAnswer,
    round,
    answers,
    challenger,
    challengerRound,
    selectedAnswerByChallenger,
    playOrFetchRound,
    opponent,
    setAnsweredIn,
    answeredIn,
    timer,
    setTimer,
    setInitCountDown,
    initCountDown,
    opponentScore,
  };
};
