import { useQuery } from 'react-query';
import api from './../../config/api';

export const RewardHandler = ({ challengeId }: { challengeId: string }) => {
  return useQuery(['events'], async () => {
    const response = await api.fetchChallengerReward({ params: challengeId });
    return response.data;
  });
};
export default RewardHandler;
