import React from "react";

function UserAgreement() {
  return (
    <div className="overflow-y-auto bg-primary text-gray-300 h-full no-scrollbar">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8">Ogmi User Agreement</h1>
        <p className="mb-4">
          Welcome to Ogmi! By using our services, you agree to the following terms and conditions:
        </p>
        <h2 className="text-2xl font-bold mt-8 mb-4">Data Collection</h2>
        <p className="mb-4">
          We collect data from you in order to provide our services. This data may include your name, email address, and other information that you provide to us. We will never share your data with third parties unless required by law or for the purpose of providing our services to you.
        </p>
        <h2 className="text-2xl font-bold mt-8 mb-4">Use of Cookies</h2>
        <p className="mb-4">
          We use cookies to improve your experience on our website. Cookies are small text files that are stored on your device. By using our website, you consent to our use of cookies.
        </p>
        <h2 className="text-2xl font-bold mt-8 mb-4">Disclaimer</h2>
        <p className="mb-4">
          Our services are provided on an "as is" and "as available" basis. We make no warranties, either express or implied, regarding the reliability, quality, or suitability of our services for your particular purpose. We are not responsible for any loss or damage that may result from your use of our services.
        </p>
        <h2 className="text-2xl font-bold mt-8 mb-4">Governing Law</h2>
        <p className="mb-4">
          This User Agreement shall be governed by and construed in accordance with the laws of the European Union. Any dispute arising under or in connection with this Agreement shall be resolved by the courts of the European Union.
        </p>
        <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions or concerns about this User Agreement, please contact us at support@ogmi.com.
        </p>
      </div>
    </div>
  )
}

export default UserAgreement;