import React from 'react';
import { ReactQuery } from './../../../types/api';
import { EventCardList } from './../../../components/organisms';
import { EventCardListProps } from './../../../components/organisms/EventCardList/EventCardList';

export interface Response extends EventCardListProps {
  title: string;
}

const handleWheel = (e) => {
  if (e.deltaY !== 0 && Math.abs(e.deltaX) <= 1) {
    e.currentTarget.parentElement.parentElement.scrollTo({
      top: e.currentTarget.parentElement.parentElement.scrollTop + e.deltaY
    });
  }
};

function EventsPerCategory({ categories, status }: ReactQuery & { categories: Response[] }) {
  if (status === 'success')
    return <div
      onWheel={handleWheel}>
      {categories.map(({ title, events }: Response, index) => (
        <div key={`${index}-events-per-category`} className='mb-4'>
          <p className='pl-4 text-gray-400 font-bold block text-2xl'>
            {title}
          </p>
          <div>
            <EventCardList status={status} events={events} />
          </div>
        </div>
      ))}
    </div>

  return null;
}

export default EventsPerCategory;