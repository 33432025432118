
import React from 'react';
import { useNavigate } from 'react-router-dom';

import AnalyticsDisplay from './AnalyticsDisplay';
import RoundedUserProfileImage from './RoundedUserProfileImage';
import RoundedLoginButton from './RoundedLoginButton';

import { useUser } from './../../../contexts/UserContext';
import { useSheet } from './../../../contexts/SheetContext/SheetContext';

import arrowUp from './../../../assets/svgs/arrow_up.svg';
import arrowDown from './../../../assets/svgs/arrow_down.svg';
import info from './../../../assets/svgs/info.svg';


function ArrowRender(position: undefined | null | "up" | "down" | "no change") {
  if (position) {
    if (position === 'up') {
      return <img className='z-10 ml-1' src={arrowUp} />
    }

    if (position === 'down') {
      return <img className='z-10 ml-1' src={arrowDown} />
    }
  }

  return null;
}

function ProfileSection() {
  const { setSheet } = useSheet();
  const { user, logout, rank } = useUser();
  const navigate = useNavigate();

  const userProfileImage = user?.profile_image_url;

  const totalGames = user?.statistic?.total_games
  const totalScore = user?.statistic?.score;

  return <div className='z-10'>
    {user ?
      <RoundedUserProfileImage userProfileImage={userProfileImage} handleOnClick={logout} /> :
      <RoundedLoginButton handleOnClick={() => setSheet('login')} />
    }
    <div className='flex my-4 align-center space-x-2 px-4'>
      <AnalyticsDisplay title="Played" value={totalGames} />
      <AnalyticsDisplay title="Total Points" value={totalScore} />
      <AnalyticsDisplay titleLeftComponent={<img className='h-3.5 mr-1 mb-0.5' src={info} />} onClick={() => navigate('/leaderboard')} title="Rank" value={rank?.rank} rightComponent={ArrowRender(rank?.rank_change)} />
    </div>
  </div>
}

export default ProfileSection;