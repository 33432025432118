import { cva, VariantProps } from 'cva';
import blankProfileImage from '../../../assets/images/blank_profile_picture.png';
import React from 'react';

const className = cva('rounded-full object-cover text-center', {
  variants: {
    size: {
      small: ['w-10', 'h-10'],
      medium: ['w-12 h-12 se:w-10 se:h-10'],
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

interface UserImageProps extends VariantProps<typeof className> {
  userImage?: string;
  hidden?: boolean;
}

const UserImage = ({ userImage, hidden, size }: UserImageProps) => {
  return (
    !hidden && (
      <img
        referrerPolicy="no-referrer"
        className={className({ size })}
        src={userImage ? userImage : blankProfileImage}
      />
    )
  );
};

export default UserImage;
