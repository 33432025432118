import React from 'react';
import { useParams } from 'react-router-dom';

import Leaderboard from './Leaderboard';
import Page from '../../components/organisms/Page';
import { LoadingSpinner } from './../../components/molecules';

import rankingLogsHandler from './../../services/rankingLogs';
import eventHandler from './../../services/events/show';

function LeaderboardWrapper() {
  const { slug } = useParams();
  const response = rankingLogsHandler({ params: { event_slug: slug } })
  const eventResponse = eventHandler({ params: { slug } })

  if (response.status === 'success')
    return (
      <Page className='bg-primary' hasOpacityTransition>
        <Leaderboard players={response.data} event={eventResponse.data} />
      </Page>
    )

  if (response.status === 'loading')
    return <Page className='bg-primary'>
      <LoadingSpinner show />
    </Page>

  return null
}

export default LeaderboardWrapper;