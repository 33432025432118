import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import Events from './Events';

import api from './../../config/api';

import { saveTokenLocally } from './../../helpers/helpers';

import { useUser } from './../../contexts/UserContext';

import Page from '../../components/organisms/Page';
import { LoadingSpinner } from './../../components/molecules';

export default function EventsWrapper() {
  const { setToken, user } = useUser();

  const response = useQuery(['events', user?.id], async () => {
    const response = await api.fetchCategoriesWithEvents({});
    return response.data
  })

  useEffect(() => {
    const params = (new URL(document.location.href)).searchParams;

    const oauth_token = params.get("oauth_token");
    const oauth_verifier = params.get("oauth_verifier");
    const callbackUrl = params.get("callback_url");


    if (oauth_token && oauth_verifier) {
      api.loginAuthCallback({
        params: {
          oauth_token,
          oauth_verifier,
          provider: 'twitter',
          location: document.location.pathname
        },
        onSuccess: (response) => {
          saveTokenLocally(response.data.token)
          setToken(response.data.token)

          // Push the event to dataLayer
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({ 'event': 'Signup' });

          if (callbackUrl) {
            window.location.replace(callbackUrl)
          }
        }
      })
    }
  }, [])

  if (response.status === 'success')
    return (
      <Page className='bg-primary' hasOpacityTransition>
        <Events user={user} categories={response.data} status={'success'} />
      </Page>
    )

  if (response.status === 'loading')
    return (
      <Page className='bg-primary'>
        <LoadingSpinner show />
      </Page>
    )

  return null
}