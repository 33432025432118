import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import usePageMeta from './../../hooks/usePageMeta';

export default function Countdown() {
  const navigate = useNavigate();
  const [count, setCount] = useState(3);

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      navigate(document.location.pathname.replace('play', 'match'))
    }
  }, [count, history]);

  const renderContent = useMemo(() => {
    switch (count) {
      case 3:
        return {
          title: 'Get ready',
          background: 'bg-indigo-900',
          themeColor: '#182C82'
        }
      case 2:
        return {
          title: 'Think quick',
          background: 'bg-indigo-1000',
          themeColor: '#0F1E61'
        }

      default:
        return {
          title: 'Let’s goooo...',
          background: 'bg-indigo-1100',
          themeColor: '#0C1746'
        }
    }
  }, [count])

  usePageMeta({ themeColor: renderContent.themeColor });

  return (
    <div className={`flex flex-1 flex-col ${renderContent.background}`}>
      <div className='flex flex-1 flex-col items-center justify-center mb-12'>
        <div className='flex flex-col px-4 justify-center'>
          <h2 className='text-80 font-bold text-center text-white py-4'>{count}</h2>
          <h2 className='text-50 font-bold text-center text-white py-4'>{renderContent.title}</h2>
        </div>
      </div>
    </div >
  );
}
