import { AnalyticsBrowser } from '@segment/analytics-next'

let segment: any = {
  track: (...arg) => { 
    console.warn('************************')
    console.warn('🚀 SEGMENT TRACKING EVENT: 🚀')
    console.warn(arg)
    console.warn('************************')
  }
}

if(process.env.REACT_APP_TRACKING_ENABLED && process.env.REACT_APP_ENVIRONMENT == 'development'){
  segment = AnalyticsBrowser.load({ writeKey: "3zBUaMu4OGOdaWmqK0t1XUj7Dq2BnjWZ"});
}else if(process.env.REACT_APP_ENVIRONMENT == 'staging' ){
  segment = AnalyticsBrowser.load({ writeKey: "LryZZNBJhTP8oK32gFFZKTFkR4YyHGJs"});
}else if(process.env.REACT_APP_ENVIRONMENT == 'production'){
  segment = AnalyticsBrowser.load({ writeKey: "Heyr7wlFBRt6E4BR7nwzRZiVHUEPpLqM"});
}

export default segment