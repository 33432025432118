import { useQuery } from 'react-query';
import api from '../../../config/api';
import { useEffect, useState } from 'react';
import { IChallenge, IUser } from '../../../types/api';

interface IUseMatchingReturn {
  opponent: IUser;
  challenge: IChallenge;
  startChallenge: boolean;
}

const useMatching = ({ slug }: { slug: string }): IUseMatchingReturn => {
  const [opponent, setOpponent] = useState();
  const [startChallenge, setStartChallenge] = useState(false);
  const [challenge, setChallenge] = useState();

  const response = useQuery(['matching', slug], async () => {
    const response = await api.createMatching({ params: { slug } });
    return response.data;
  });

  useEffect(() => {
    const data = response?.data;
    if (data) {
      setOpponent(data.opponent);
      setChallenge(data.challenge);
    }
  }, [response?.data]);

  useEffect(() => {
    let allowStartTimeout;
    if (opponent) {
      allowStartTimeout = setTimeout(() => setStartChallenge(true), 2000);
    }
    return () => clearTimeout(allowStartTimeout);
  }, [opponent]);

  return {
    opponent,
    challenge,
    startChallenge,
  };
};

export default useMatching;
