import React from 'react';

const RewardText = ({
  opponentName,
  isDraw,
  userIsWinner,
  points,
}: {
  opponentName: string;
  isDraw: boolean;
  userIsWinner: boolean;
  points: number;
}) => (
  <div className={'text-white font-bold text-2xl'}>
    {isDraw ? (
      <div>
        tie with <span className={'text-pink-500'}>@{opponentName}</span>
      </div>
    ) : !isDraw && userIsWinner ? (
      <>
        You beat <span className={'text-pink-500'}>@{opponentName}</span> by
        <div className={'text-3xl flex justify-center items-center font-extrabold'}>
          {points} <div className={'text-xl font-bold pl-1'}> points</div>
        </div>
      </>
    ) : (
      <>
        <span className={'text-pink-500'}>@{opponentName}</span> won by
        <div className={'text-3xl flex justify-center items-center font-extrabold'}>
          {points} <div className={'text-xl font-bold pl-1'}> points</div>
        </div>
      </>
    )}
  </div>
);

export default RewardText;
