// This is the code that will run in our worker
const workerScript = `
  let intervalId = null;

  self.onmessage = function(event) {
    if (event.data === 'stop') {
        if(intervalId){
            clearInterval(intervalId);
        }
      return;
    }

    let count = event.data;
    intervalId = setInterval(() => {
      if (count) {
        count -= 0.1;
        postMessage(count);
      } else {
        if(intervalId) {
            clearInterval(intervalId);
        }
      }
    }, 100);
  }
`;
// We use a Blob to create a URL for our worker
const workerScriptBlob = new Blob([workerScript]);
const workerUrl = window.URL.createObjectURL(workerScriptBlob);
export const worker = new Worker(workerUrl);
