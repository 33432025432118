import React, { useEffect, useState } from 'react';

import { IUser } from './../../../../types/api';
import instance from './../../../../config/axios';

import RoundedUserProfileImage from './../../../../pages/Events/components/RoundedUserProfileImage';
import AnalyticsDisplay from './../../../../pages/Events/components/AnalyticsDisplay';

function UserStatistcs({ userId }: { userId?: number | string }) {
  const [user, setUser] = useState<null | IUser>();
  const userProfileImage = user?.profile_image_url;

  const totalGames = user?.statistic?.total_games
  const totalScore = user?.statistic?.score;
  const rank = user?.rank;

  useEffect(() => {
    fetchUser(userId);
  }, [userId])

  const fetchUser = async (userId) => {
    try {
      const result = await instance.get(`/users/${userId}`);
      console.warn('Fetching user result', result);

      setUser(result.data)
    } catch (error) {
      console.warn('Fetching user error', error);
    }
  };

  return <div className='flex flex-col justify-between h-full py-4'>
    <div className='text-center font-semibold text-2xl'>
      {user?.display_name}
    </div>

    <RoundedUserProfileImage userProfileImage={userProfileImage} />

    <div className='flex my-4 align-center space-x-2 px-4'>
      <AnalyticsDisplay title="Played" value={totalGames} blockClassName='opacity-80' titleClassName='opacity-90' valueClassName='z-10' />
      <AnalyticsDisplay title="Total Points" value={totalScore} blockClassName='opacity-80' titleClassName='opacity-90' valueClassName='z-10' />
      <AnalyticsDisplay title="Rank" value={rank} blockClassName='opacity-80' titleClassName='opacity-90' valueClassName='z-10' />
    </div>
  </div>
}

export default UserStatistcs;