import React from 'react';
import { useParams } from 'react-router-dom';

import { IEvent, IUser } from '../../types/api';
import { Header } from './../../components/organisms';

import Leaderboard from './components/Leaderboard'

export default function LeaderboardScreen({ players, event }: { players: IUser[]; event?: IEvent }) {
  const { slug } = useParams();

  return (
    <div className='flex flex-1 flex-col overflow-y-auto'>
      <Header hasBack backLink={`/events/${slug}`} hasShare title={`${event.category.title} : ${event?.name}`} shareTitle={event?.name} shareDescription={`Here are the brightest brains in ${event.category.title} : ${event?.name}! Can you take their spot?`} />
      <Leaderboard players={players} status={'success'} />
    </div >
  );
}
