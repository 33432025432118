import { IUser } from '../../../types/api';
import blankProfileImage from '../../../assets/images/blank_profile_picture.png';
import React, { useEffect, useState } from 'react';
import Loading from './Loading';
import { motion } from 'framer-motion';

const ChallengerCard = ({ user, isOpponent }: { user?: IUser; isOpponent?: true }) => {
  const [showOpponent, setShowOpponent] = useState(false);
  useEffect(() => {
    if (user && isOpponent) setTimeout(() => setShowOpponent(true), 2000);
  }, [user?.id]);

  return (
    <div className="h-64 bg-indigo-800 w-44 rounded-3xl items-center flex flex-col justify-center">
      {!isOpponent || showOpponent ? (
        <>
          <div
            className="flex-shrink-0 bg-indigo-300"
            style={{
              borderRadius: 80,
              clipPath: 'polygon(-4% 50%, 25% 0%, 76% 0%, 104% 50%, 75% 100%, 25% 100%)',
              padding: 2,
            }}
          >
            <motion.img
              initial={isOpponent && { opacity: 0 }}
              animate={isOpponent && { opacity: 1 }}
              transition={{ duration: 1 }}
              style={{ clipPath: 'polygon(-4% 50%, 25% 0%, 76% 0%, 104% 50%, 75% 100%, 25% 100%)' }}
              className="w-24 h-24 object-cover rounded-full"
              src={user?.profile_image_url || blankProfileImage}
            />
          </div>
          <span className="font-bold text-white mt-4">
            {user ? user.display_name : 'Finding opponent'}
          </span>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ChallengerCard;
