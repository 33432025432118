import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import { IAnswer } from '../../types/api';
import { useUser } from '../../contexts/UserContext';
import RoundTransition from '../../shared/screens/RoundTransition';
import usePageMeta from './../../hooks/usePageMeta';
import {
  TimerCountDown,
  Question,
  Answer,
  Round,
  AnsweredQuestion,
  ScoreDisplay,
} from './components';
import { worker } from '../../helpers/worker';
import { INITIAL_TIME, TRANSITION_TIME } from '../../helpers/constant';
import { useMatch } from './hooks';
import { didTheUserClickedOnThisAnswer, isCorrectCheck, isWrongCheck } from '../../helpers/helpers';

export default function MatchScreen() {
  const { challengeId, title } = useParams();
  const { user } = useUser();
  const userImage = user?.profile_image_url;
  const {
    correctAnswer,
    answerStatus,
    setSelectedAnswer,
    selectedAnswer,
    round,
    answers,
    selectedAnswerByChallenger,
    playOrFetchRound,
    opponent,
    setAnsweredIn,
    answeredIn,
    timer,
    setTimer,
    setInitCountDown,
    initCountDown,
    opponentScore,
  } = useMatch({ challengeId });

  // use date instead of decreasing 0.1
  // avoid rerendering
  const [newRound, setNewRound] = useState(false);
  // const [timerSnapshot, setTimerSnapshot] = useState<number>(null);
  const [startTime, setStartTime] = useState(null);

  usePageMeta({ themeColor: '#000000' });

  useEffect(() => {
    if (round && round.id) {
      setNewRound(true);
      setStartTime(new Date());
      worker.postMessage('stop');
      worker.postMessage(INITIAL_TIME);
    }
  }, [round?.id]);

  useEffect(() => {
    if (newRound) {
      setTimeout(() => {
        setNewRound(false);
      }, 2000);
    }
  }, [newRound]);

  useEffect(() => {
    worker.onmessage = (event) => {
      setTimer(event.data);
    };
    return () => worker.postMessage('stop');
  }, []);

  useEffect(() => {
    if (timer <= 0) {
      playOrFetchRound({ challengeId });
      if (initCountDown) setInitCountDown(false);
    }
  }, [timer]);

  const handleAnswerClick = (item: IAnswer) => {
    if (timer > 10) return;
    setSelectedAnswer(item.id);
    playOrFetchRound({ answerId: item.id, challengeId });
    if (startTime) {
      const currentTime = new Date();
      const timeDifference = (currentTime.getTime() - startTime) / 1000;
      setAnsweredIn(timeDifference - TRANSITION_TIME);
    }
    // setTimerSnapshot(timer);
  };

  if (newRound && round?.challenge?.total_rounds && round?.current_round) {
    return (
      <RoundTransition
        title={title}
        totalRounds={round?.challenge?.total_rounds}
        currentRound={round?.current_round}
      />
    );
  }
  return (
    <div className="flex flex-col items-center flex-1 bg-black overflow-y-auto overflow-x-hidden py-4">
      <div className="items-center w-full max-w-sm px-3 sm:px-0">
        {round?.id && (
          <div className="flex w-full justify-between h-16 items-center">
            <ScoreDisplay
              totalScore={round?.total_score}
              status={answerStatus}
              user={user}
              opponent={opponent}
            />
            <TimerCountDown initCountDown={initCountDown} defaultTime={INITIAL_TIME} />
            <Round
              totalScore={round?.total_score}
              currentRound={round.current_round}
              totalRounds={round.challenge?.total_rounds}
              answeredIn={answeredIn}
              opponent={opponent}
              opponentScore={opponentScore}
              selectedAnswerByChallenger={selectedAnswerByChallenger}
            />
          </div>
        )}
      </div>

      {selectedAnswer && selectedAnswer === correctAnswer && (
        <div className="absolute top-1/2">
          <ConfettiExplosion
            {...{
              force: 0.6,
              duration: 2500,
              particleCount: 80,
              width: 1000,
            }}
          />
        </div>
      )}

      <div className="flex flex-1 flex-col w-full max-w-sm justify-around">
        {timer === null || timer <= 0 ? (
          <></>
        ) : (
          <>
            <Question title={round?.question?.title} />
            <div
              className={`fade-in ${
                timer <= 10 && 'instant-fade-in'
              } space-y-4 flex flex-col justify-center px-6 sm:px-0`}
            >
              {answers?.map((item: IAnswer) => {
                const isCorrect = isCorrectCheck(correctAnswer, item.id);

                let isWrong = isWrongCheck(correctAnswer, item.id);

                if (opponent.round?.id) {
                  isWrong =
                    isWrong &&
                    (didTheUserClickedOnThisAnswer(selectedAnswer, item.id) ||
                      didTheUserClickedOnThisAnswer(selectedAnswerByChallenger, item.id));
                } else {
                  isWrong = isWrong && didTheUserClickedOnThisAnswer(selectedAnswer, item.id);
                }
                return (
                  <div key={`${item.id}-answer-button`} className="relative">
                    <Answer
                      id={item.id}
                      isCorrect={isCorrect}
                      isWrong={isWrong}
                      selectedAnswer={selectedAnswer}
                      title={item.title}
                      isClickable={timer <= 30}
                      onClick={() => handleAnswerClick(item)}
                    />
                    <AnsweredQuestion
                      time={answeredIn}
                      userImage={userImage}
                      show={item.id === selectedAnswer}
                    />
                    <AnsweredQuestion
                      right
                      time={opponent.round?.answered_in}
                      userImage={opponent.data?.profile_image_url}
                      show={item.id === selectedAnswerByChallenger}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
