import React from 'react';

import Match from './Match';
import Page from '../../components/organisms/Page';

function MatchWrapper() {
  return (
    <Page hasLogo className='bg-black'>
      <Match />
    </Page>
  )
}

export default MatchWrapper;