
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import api from '../config/api';
import instance from './../config/axios';

import { deleteTokenLocally, getTokenLocally } from '../helpers/helpers';
import { IUser, IRank } from './../types/api';

export type WithChildren = {
  children: ReactNode;
};
interface AuthContextValueSafe {
  token?: string | null;
  setToken: (arg: string) => void;
  user: null | IUser;
  logout: () => void;
  rank: null | IRank;
}

type AuthContextValue = AuthContextValueSafe

const AuthContext = createContext<AuthContextValue>({
  setToken: () => null,
  user: null,
  logout: () => null,
  rank: null,
});

export const UserProvider = ({ children }: WithChildren) => {
  const [token, setToken] = useState(() => getTokenLocally());
  const [user, setUser] = useState(null);
  const [rank, setRank] = useState(null);

  useQuery(['currentUser', user?.id], async () => {
    const response = await api.fetchMe()
    setUser(response.data)
  }, {
    onError: (response: { response: AxiosResponse<any> }) => {
      const UNAUTHORIZED = 401;

      if (response?.response?.status === UNAUTHORIZED) {
        setToken(null);
        deleteTokenLocally();
      }
    },
    enabled: token != null,
    refetchOnWindowFocus: false,
  })

  useQuery(['currentRank', user?.id], async () => {
    const result = await instance.get(`/rankings/${user?.id}`);

    setRank(result.data)
  }, {
    onError: (response) => {
      console.warn('Error on fetching the user rank ', response)
    },
    enabled: user != null,
    refetchOnWindowFocus: false,
  })

  const logout = async () => {
    setToken(null);
    deleteTokenLocally();
    setUser(null);
    setRank(null);
  }

  const data: AuthContextValue = useMemo(
    () => ({
      setToken,
      user,
      logout,
      rank
    }),
    [setToken, user, rank]
  );

  return (
    <AuthContext.Provider value={data}>
      {children}
    </AuthContext.Provider>
  );
};

export const useUser = () => useContext(AuthContext);
