import { useQuery } from "react-query";

import api from "./../../config/api";

export default function handler({params}) {
  const response = useQuery(['challengeResults', params.id], async () => {
    const response = await api.challengeResults({params});
    return response.data
  })
  
  return response;
}