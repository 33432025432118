import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import { isDesktop } from 'react-device-detect';

import { IUser } from '../../types/api';
import { Header } from './../../components/organisms';

import Leaderboard from './../Leaderboard/components/Leaderboard'
import MyLeaderboard from './components/MyLeaderboard'

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export default function GlobalLeaderboardScreen({ players, player }: { players: IUser[]; player: IUser }) {
  const leaderboardRef = useRef(null);

  const captureScreen = async () => {
    const canvas = await html2canvas(document.body, { useCORS: true });
    return canvas.toDataURL();
  };

  const share = async () => {
    const capturedImageBase64 = await captureScreen();
    const blob = dataURItoBlob(capturedImageBase64);
    const file = new File([blob], "leaderboard.jpeg", { type: "image/jpeg" });

    if (navigator.share) {
      // Use Web Share API if available
      try {
        await navigator.share({
          title: 'Global Leaderboard',
          text: 'Check out my rank on OGMI!',
          url: document.location.href,
          files: [file]
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      // Fallback to custom share options
      console.log('Web Share API not available. Implement custom share options.');
    }
  };

  return (
    <div ref={leaderboardRef} className='flex flex-1 flex-col overflow-y-auto'>
      <Header hasBack backLink={`/events`} hasShare={!isDesktop} title='Global Leaderboard' onShare={share} />

      {player && <p className='py-2 mx-auto w-11/12 font-bold text-white text-2xl'>My position</p>}
      <MyLeaderboard player={player} status={'success'} />
      <p className='py-2 mx-auto w-11/12 font-bold text-white text-2xl'>Top players</p>
      <Leaderboard players={players} status={'success'} extraContainerClasses='mb-2' />
    </div >
  );
}
