import React from 'react';
import PrivacyPolicy from './PrivacyPolicy';
import Page from '../../components/organisms/Page';

function PrivacyPolicyWrapper() {
  return (
    <Page className='bg-primary'>
      <PrivacyPolicy />
    </Page>
  )
}

export default PrivacyPolicyWrapper;