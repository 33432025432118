import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import challengeResultsHandler from './../../services/challengeResults/show';
import usePageMeta from './../../hooks/usePageMeta';
import fireworksGif from './../../assets/gifs/fireworks.gif';
import RewardHandler from '../../services/rewards';
import { useUser } from '../../contexts/UserContext';
import MatchResultWithOpponent from './components/MatchResultWithOpponent';

const MatchReward = () => {
  usePageMeta({ themeColor: '#000000' });

  useEffect(() => {
    const element = document.querySelector('.block-swipe-nav');
    const handleTouchStart = (e) => {
      if (e.pageX) {
        if (e.pageX > 10 && e.pageX < window.innerWidth - 10) {
          return;
        }
        e.preventDefault();
      }
    };
    if (element) element.addEventListener('touchstart', handleTouchStart);

    return () => {
      if (element) element.removeEventListener('touchstart', handleTouchStart);
    };
  }, [document.querySelector('.block-swipe-nav')]);

  const { challengeId } = useParams();
  const { user } = useUser();
  const response = challengeResultsHandler({ params: { id: challengeId } });
  const rewardResponse = RewardHandler({ challengeId });
  const rewards = rewardResponse?.data;

  const opponent = rewards?.participants?.filter((participate) => participate?.id !== user?.id);
  const userIsWinner = rewards?.winner?.id === user?.id;

  if (response.status !== 'success') return null;

  return (
    <div className="w-full px-3 block-swipe-nav overflow-y-auto overflow-x-hidden flex flex-col relative">
      <MatchResultWithOpponent
        user={user}
        opponent={opponent?.[0]}
        userIsWinner={userIsWinner}
        isDraw={!rewards?.winner}
        points={rewards?.amount}
      />
      <img src={fireworksGif} className="absolute top-0 left-0 opacity-30 -z-2" />
      <img src={fireworksGif} className="absolute bottom-0 right-0 opacity-30 -z-2" />
    </div>
  );
};

export default MatchReward;
