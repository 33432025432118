import { useQuery } from "react-query";

import api from "./../../config/api";

export default function handler({params}) {
  const response = useQuery(['rankingsMe'], async () => {
    const response = await api.fetchRankingMe({});
    return response.data
  })
  
  return response;
}