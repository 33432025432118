import { motion } from 'framer-motion';
import React from 'react';
import UserImage from './UserImage';

const AnsweredQuestion = ({
  userImage,
  time,
  show,
  right,
}: {
  userImage;
  time;
  show;
  right?: boolean;
}) => {
  if (!show) return;
  const formattedTime = (time || 0.0).toFixed(1);

  return (
    <motion.div
      initial={right && { x: 100, opacity: 0.1 }}
      animate={right && { x: 0, opacity: 1 }}
      transition={{ duration: 1 }}
      className={`absolute -top-3 se:-top-4 ${
        right ? '-right-6 se:-right-7' : '-left-6 se:-left-5'
      }`}
    >
      <div className="relative w-12 h-7 bg-sky-500 text-center font-bold rounded mx-auto">
        <span className="text-blue-800 text-lg align-sub">{formattedTime}s</span>
        <div className="border-solid border-t-sky-500 border-t-8 border-x-transparent border-x-8 border-b-0 absolute -bottom-1 left-1/2 transform -translate-x-1/2" />
      </div>
      <UserImage userImage={userImage} />
    </motion.div>
  );
};

export default AnsweredQuestion;
