import React from 'react';

const Answer = ({
  id,
  onClick,
  title,
  isCorrect,
  isWrong,
  selectedAnswer,
  isClickable,
}: {
  onClick: () => void;
  title: string;
  isCorrect: boolean;
  isWrong: boolean;
  selectedAnswer: string;
  isClickable?: boolean;
  id: string;
}) => {
  if (isWrong || isCorrect) {
    return (
      <div
        className={`flex items-center justify-center h-18 se:h-16 px-2 truncate text-ellipsis w-full font-bold text-black text-center rounded-2xl text-xl select-none ${
          isWrong && 'bg-red-400'
        } ${isCorrect && 'bg-green-400'}`}
      >
        <span>{title}</span>
      </div>
    );
  }

  if (selectedAnswer) {
    return (
      <div
        className={`flex items-center justify-center h-18 se:h-16 px-2 truncate text-ellipsis w-full font-bold bg-white text-black text-center rounded-2xl text-xl transition-opacity duration-1000 opacity-0 select-none`}
      >
        <span>{title}</span>
      </div>
    );
  }

  return (
    <div
      className={`flex items-center justify-center h-18 se:h-16 px-2 truncate text-ellipsis w-full font-bold bg-white text-black text-center rounded-2xl text-xl ${
        isClickable ? 'cursor-pointer' : 'cursor-default'
      } select-none`}
      onClick={onClick}
    >
      <span>{title}</span>
    </div>
  );
};

export default Answer;
