import React, { useEffect, useState } from 'react';
import { IOpponent, IOpponentRound } from '../../../types/api';
import CustomImage from '../../../components/organisms/CustomImage';
import { isFirstToAnswer, selectFirstName } from '../../../helpers/helpers';
import ThinkPopup from './ThinkPopup';
import { useSheet } from '../../../contexts/SheetContext/SheetContext';

interface IRound {
  totalScore: number;
  currentRound: number;
  totalRounds: number;
  answeredIn: number | null;
  selectedAnswerByChallenger: string | null;
  opponent: {
    data: IOpponent | null;
    round: IOpponentRound | null;
    score: number | null;
  };
  opponentScore: number | null;
}

const Round = (props: IRound) => {
  const {
    currentRound,
    totalRounds,
    opponent,
    totalScore,
    answeredIn,
    selectedAnswerByChallenger,
    opponentScore,
  } = props;
  const { data: opponentData, round: opponentRound } = opponent;
  const [delayOpen, setDelayOpen] = useState(false);

  useEffect(() => {
    const delayTimeout = setTimeout(
      () => setDelayOpen(isFirstToAnswer(answeredIn, opponentRound?.answered_in)),
      2000
    );
    return () => clearTimeout(delayTimeout);
  }, [answeredIn]);

  return (
    <div className="text-center">
      {opponentData || opponentRound ? (
        <>
          <div className={'flex justify-between items-center'}>
            <div className={'pr-2'}>
              <p className={'font-bold text-xs text-slate-500'}>
                {opponentData && selectFirstName(opponentData.display_name)}
              </p>
              <p
                className={`font-bold text-lg ${
                  opponent.round.total_score === totalScore
                    ? 'text-slate-500'
                    : opponent.round.total_score < totalScore
                    ? 'text-red-400'
                    : 'text-green-400'
                }`}
              >
                {opponentScore}
              </p>
            </div>
            <CustomImage
              imageUrl={opponentData?.profile_image_url}
              size={42}
              borderColor={'bg-gray-200'}
            />
          </div>
          <div className={'pr-12'}>
            <ThinkPopup isOpen={delayOpen && !selectedAnswerByChallenger} />
          </div>
        </>
      ) : (
        <>
          <p className="font-bold text-slate-500 text-xs">Round</p>
          <p className="font-bold text-slate-500 text-lg">
            {currentRound}/{totalRounds}
          </p>
        </>
      )}
    </div>
  );
};

export default Round;
