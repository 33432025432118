import React from 'react';

import EventCard from './../../molecules/EventCard';
import { IEvent } from './../../../types/api';

import { isDesktop } from 'react-device-detect'

export interface EventCardListProps {
  events: IEvent[];
}

function EventCardList({ events }: EventCardListProps) {
  return (
    <div className={`flex overflow-x-auto w-full max-w-md space-x-4 py-4 ${isDesktop ? 'custom-scrollbar' : 'no-scrollbar'}`}>
      <div className='w-4' />
      {events.map((event, index) => (
        <EventCard key={`${index}-event-card`} event={event} />
      ))}
      <div className='w-4' />
    </div>
  )
}

export default EventCardList;