import React from 'react';
import EventCardList, { EventCardListProps } from './EventCardList';
import { ReactQuery } from './../../../types/api';

function EventCardListWrapper({ events, status }: EventCardListProps & ReactQuery) {
  if (status === 'success')
    return <EventCardList events={events} />

  return null;
}

export default EventCardListWrapper;