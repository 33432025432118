import React from 'react';

import GlobalLeaderboard from './GlobalLeaderboard';
import Page from '../../components/organisms/Page';
import { LoadingSpinner } from './../../components/molecules';

import rankingsHandler from './../../services/rankings';
import rankingsMeHandler from './../../services/rankings/me';

function GlobalLeaderboardWrapper() {
  const response = rankingsHandler({ params: {} })
  const responseMe = rankingsMeHandler({ params: {} })

  if (response.status === 'success')
    return (
      <Page className='bg-primary' hasOpacityTransition>
        <GlobalLeaderboard player={responseMe.data} players={response.data} />
      </Page>
    )

  if (response.status === 'loading')
    return <Page className='bg-primary'>
      <LoadingSpinner show />
    </Page>

  return null
}

export default GlobalLeaderboardWrapper;