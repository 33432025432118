import React from 'react';
import CustomImage from '../../../components/organisms/CustomImage';
import { IOpponent, IOpponentRound, IUser } from '../../../types/api';
import { selectFirstName } from '../../../helpers/helpers';

interface IScoreDisplay {
  totalScore: number;
  status: 'pending' | string;
  user: IUser;
  opponent: {
    data: IOpponent | null;
    round: IOpponentRound | null;
  };
}
const ScoreDisplay = (props: IScoreDisplay) => {
  const { totalScore, status, opponent, user } = props;
  return (
    <div className="flex">
      <div className="text-center">
        {opponent.data || opponent.round ? (
          <div className={'flex justify-between items-center tex'}>
            <CustomImage imageUrl={user?.profile_image_url} size={42} borderColor={'bg-gray-200'} />
            <div className={'pl-2'}>
              <p className={'font-bold text-xs text-slate-500'}>
                {user?.display_name && selectFirstName(user.display_name)}
              </p>
              <p
                className={`text-lg font-bold ${
                  opponent.round.total_score === totalScore
                    ? 'text-slate-500'
                    : opponent.round.total_score > totalScore
                    ? 'text-red-400'
                    : 'text-green-400'
                }`}
              >
                {totalScore}
              </p>
            </div>
          </div>
        ) : (
          <>
            <p className="font-bold text-slate-500 text-xs">Score</p>
            <p
              className={`text-lg font-bold ${
                status === 'success'
                  ? 'text-green-400'
                  : status === 'error'
                  ? 'text-red-400'
                  : 'text-slate-500'
              }`}
            >
              {totalScore}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ScoreDisplay;
