import { useEffect } from "react";

const usePageMeta = ({title, description, themeColor}: {title?: string; description?: string; themeColor?: string}) =>{
  const defaultTitle = "OGMI";
  const defaultDesc = "Play the best social trivia experiences";
  const defaultThemeColor = "#182C82";
  
  useEffect(() => {
      document.title = title || defaultTitle;
      document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);
      document.querySelector("meta[name='theme-color']").setAttribute("content", themeColor || defaultThemeColor);
  }, [defaultTitle, title, defaultDesc, description, defaultThemeColor, themeColor]);
};

export default usePageMeta;