import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../../contexts/UserContext';
import { useSheet } from '../../contexts/SheetContext/SheetContext';

import lockIcon from './../../assets/svgs/lock.svg';
import checkIcon from './../../assets/svgs/check.svg';
import playIcon from './../../assets/svgs/play.svg';
import halfCirclesImage from './../../assets/images/half_circles.png';

import { IEvent, IUser } from '../../types/api';

import { Header } from './../../components/organisms';
import CustomImage from '../../components/organisms/CustomImage';

import eventAvailabilityHandler from './../../services/eventAvailability/show';
import rankingLogsHandler from './../../services/rankingLogs';

export default function Event({ event }: { event: IEvent }) {
  const { user } = useUser();
  const navigate = useNavigate();
  const [topPlayers, setTopPlayers] = useState([]);
  const { setSheet, setData } = useSheet();
  const { id, name, banner_image_url, description, slug, profile_image_url, category } = event;

  const response = eventAvailabilityHandler({ params: { slug } });
  const topPlayersResponse = rankingLogsHandler({ params: { event_id: id } });

  useEffect(() => {
    if (topPlayersResponse.data && topPlayersResponse.status === 'success') {
      setTopPlayers(topPlayersResponse.data);
    }
  }, [topPlayersResponse.data, topPlayersResponse.status]);

  const {
    penultimateChallenge,
    lastChallenge,
    currentChallenge,
    nextChallenge,
    nextNextChallenge,
  } = useMemo(() => {
    if (response.data)
      return getChallengesInfo(
        response.data.total_challenges,
        response.data.total_challenges_played
      );

    return {
      penultimateChallenge: 0,
      lastChallenge: 0,
      currentChallenge: 0,
      nextChallenge: 0,
      nextNextChallenge: 0,
    };
  }, [response]);

  const handleOnClick = () => {
    if (user) {
      play();
    } else {
      setSheet('login');
    }
  };

  const handleSeeAll = () => {
    navigate('leaderboard');
  };

  const play = () => {
    const challengeId = response.data.challenge_id;

    if (challengeId) {
      navigate(`challenges/${challengeId}/play`);
    }
  };

  const matchingOrLogin = () => {
    if (user) {
      navigate('matching');
    } else {
      setSheet('login');
    }
  }

  return (
    <div className="flex flex-1 flex-col overflow-y-auto grow relative">
      <Header
        hasBack
        backLink="/events"
        hasShare
        title={name}
        shareTitle={name}
        shareDescription={`Compete with me on ${category.title} : ${name}. Good luck beating my score!`}
      />
      <div className="relative mb-14">
        <img className="w-full h-56 object-cover -mt-8" src={banner_image_url} />
        <img
          className="h-20 w-20 rounded-full bg-gray-200 border-4 border-primary left-4 -bottom-10 absolute"
          src={profile_image_url}
        />
      </div>
      <div className="flex flex-col px-4 z-10">
        <p className="text-white">{description}</p>
        {/* <div className='flex flex-row my-4'>
          <div className='flex flex-row text-white mr-2 items-center'>
            <p>Players</p>
            <p className='font-bold text-lg ml-2 uppercase'>30k</p>
          </div>
          <div className='flex flex-row text-white items-center'>
            <p>Plays</p>
            <p className='font-bold text-lg ml-2 uppercase'>98k</p>
          </div>
        </div> */}

        <div
          className={`text-white flex flex-row items-center w-full mt-4 ${topPlayers.length === 0 && 'hidden'
            }`}
        >
          <p className="font-bold text-xl opacity-60">Top players</p>
          <p className="text-xs ml-auto cursor-pointer" onClick={handleSeeAll}>
            See All
          </p>
        </div>

        <div className="flex overflow-x-auto overflow-y-hidden w-full max-w-md no-scrollbar h-28">
          {topPlayers.map((user: IUser, index) => (
            <div
              key={index}
              className="cursor-pointer flex flex-col m-4 py-2 h-24 w-16 items-center"
              onClick={() => {
                setData({ userId: user.id });
                setSheet('user-statistics');
              }}
            >
              <CustomImage imageUrl={user.profile_image_url} />

              <p className="w-16 text-xs text-gray-300 text-center mt-2 truncate">{`${user.display_name}`}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col px-4 mb-10 space-y-2.5 z-10">
        <p className="font-bold text-white text-xl opacity-60">Test your knowledge</p>
        {isBiggerThanZero(penultimateChallenge) && (
          <QuizButton number={penultimateChallenge} iconType="check" />
        )}
        {isBiggerThanZero(lastChallenge) && <QuizButton number={lastChallenge} iconType="check" />}
        {isBiggerThanZero(currentChallenge) && (
          <QuizButton
            number={currentChallenge}
            onClick={handleOnClick}
            isPlayable={response?.data?.is_playable}
            iconType={isBiggerThanZero(nextChallenge) ? 'lock' : 'check'}
          />
        )}
        {isBiggerThanZero(nextChallenge) && <QuizButton number={nextChallenge} />}
        {isBiggerThanZero(nextNextChallenge) && <QuizButton number={nextNextChallenge} />}
      </div>
      <div className="flex flex-col px-4 mb-10 space-y-2.5 z-10">
        <p className="font-bold text-white text-xl opacity-60">Compete with others</p>

        <div
          onClick={matchingOrLogin}
          className={`flex justify-center items-center bg-pink-500 h-14 text-white font-bold rounded-xl select-none relative cursor-pointer active:bg-pink-600 transition duration-300 ease-in-out hover:opacity-90`}
        >
          <p className="text-xl">Play</p>
        </div>
      </div>
      <img
        className="lg:hidden w-full max-w-md fixed z-0 left-1/2 bottom-0 object-cover transform -translate-x-1/2"
        src={halfCirclesImage}
      />
    </div>
  );
}

const icons = {
  lock: lockIcon,
  play: playIcon,
  check: checkIcon,
};

function QuizButton({
  number,
  onClick,
  isPlayable,
  iconType = 'lock',
}: {
  number: string | number;
  onClick?: () => void;
  isPlayable?: boolean;
  iconType?: keyof typeof icons;
}) {
  if (isPlayable) {
    iconType = 'play';
  }

  return (
    <div
      onClick={() => (isPlayable ? onClick?.() : null)}
      className={`flex justify-center items-center bg-pink-500 h-14 text-white font-bold rounded-xl select-none ${!isPlayable && 'opacity-50'
        } relative ${isPlayable &&
        'cursor-pointer active:bg-pink-600 transition duration-300 ease-in-out hover:opacity-90'
        }`}
    >
      <p className="text-xl">Quiz #{number}</p>
      <img className="w-6 h-6 absolute right-4" src={icons[iconType]} />
    </div>
  );
}

function isBiggerThanZero(value) {
  return value > 0;
}

function getChallengesInfo(totalRounds, totalRoundsPlayed) {
  let penultimateChallenge = Math.max(totalRoundsPlayed - 1, 0);
  let lastChallenge = Math.max(totalRoundsPlayed, 0);
  let currentChallenge = totalRoundsPlayed + 1;
  let nextChallenge = Math.min(totalRoundsPlayed + 2, totalRounds);
  let nextNextChallenge = Math.min(totalRoundsPlayed + 3, totalRounds);

  if (totalRoundsPlayed === totalRounds) {
    currentChallenge = totalRoundsPlayed;
    nextChallenge = 0;
    nextNextChallenge = 0;
    penultimateChallenge = Math.max(totalRoundsPlayed - 2, 0);
    lastChallenge = Math.max(totalRoundsPlayed - 1, 0);
  }

  if (isBiggerThanZero(nextChallenge) && isBiggerThanZero(lastChallenge)) {
    penultimateChallenge = 0;
    nextNextChallenge = 0;
  }

  if (currentChallenge === totalRounds) {
    nextChallenge = 0;
    nextNextChallenge = 0;
  }

  if (nextNextChallenge == nextChallenge) {
    nextNextChallenge = 0;
  }

  return {
    penultimateChallenge,
    lastChallenge,
    currentChallenge,
    nextChallenge,
    nextNextChallenge,
  };
}
