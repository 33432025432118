import React from 'react';
import UserAgreement from './UserAgreement';
import Page from '../../components/organisms/Page';

function UserAgreementWrapper() {
  return (
    <Page className='bg-primary'>
      <UserAgreement />
    </Page>
  );
}

export default UserAgreementWrapper;