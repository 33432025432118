import React, { useCallback, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ogmiLogo from './../../../assets/svgs/logos/ogmi.svg';
import twitterLogo from './../../../assets/svgs/logos/twitter.svg';
import discordLogo from './../../../assets/svgs/logos/discord.svg';
import chevronLeft from './../../../assets/svgs/chevron-left.svg';
import share from './../../../assets/svgs/share.svg';
import penIcon from './../../../assets/svgs/pen.svg';

import { HeaderProps } from './index';
import { RWebShare } from "react-web-share";

function BackButton({ backLink }: { backLink?: string }) {
  const navigate = useNavigate();

  const handleOnClickBack = useCallback(() => {
    if (backLink) {
      navigate(backLink)
    } else {
      navigate(-1)
    }
  }, [navigate, backLink])

  return <div className='cursor-pointer' onClick={handleOnClickBack}>
    <div className='w-12'>
      <img className='w-6 h-6 mr-auto' src={chevronLeft} />
    </div>
  </div>
}

function OgmiLogoButton() {
  return <Link to={'/events'}>
    <img className='w-12 h-12 mr-auto cursor-pointer' src={ogmiLogo} />
  </Link>
}

export function ShareButton({ title, description, onShare }: { onShare?: () => void; title?: string; description?: string }) {
  const url = window.location.href;

  if (onShare) {
    return <div className='w-12' onClick={onShare}>
      <img className='w-7 h-7 cursor-pointer ml-auto' src={share} />
    </div>
  }

  return <div className='w-12'>
    <RWebShare
      data={{
        text: description,
        url,
        title,
      }}
    >
      <img className='w-7 h-7 cursor-pointer ml-auto' src={share} />
    </RWebShare>
  </div>
}

function DiscordButton() {
  return <Link to={'https://discord.gg/eTDfhhwsFb'} target='_blank'>
    <img className='w-7 h-7 cursor-pointer' src={discordLogo} />
  </Link>
}

function TwitterButton() {
  return <Link to={'https://twitter.com/Ogmi_io'} target='_blank'>
    <img className='w-7 h-7 cursor-pointer' src={twitterLogo} />
  </Link>
}

const Header = ({
  title = 'OGMI',
  hasBack,
  hasShare,
  hasDiscord,
  hasTwitter,
  backLink,
  shareTitle,
  shareDescription,
  onShare,
  editable,
  onEdit
}: HeaderProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const inputRef = useRef(null);

  const handleEdit = () => {
    if (editable && isEditing === false) {
      setIsEditing(true);
      setTimeout(() => inputRef.current && inputRef.current.focus(), 0);
    }
  };

  const handleSave = () => {
    setIsEditing(false);
    onEdit(editTitle);
  };

  const handleCancel = () => {
    setIsEditing(false);
    // Reset the edited title to the initial title
    setEditTitle(title);
  };

  return (
    <div className='relative mb-28 max-w-md'>
      <div className='flex p-4 items-center bg-primary header-bottom-shadow h-17 fixed z-30 h-20 w-full max-w-md'>
        <div className='w-16'>
          {hasBack && <BackButton backLink={backLink} />}
          {!hasBack && <OgmiLogoButton />}
        </div>

        <div className='flex flex-1 flex-row w-1/3 items-center mx-auto' onClick={handleEdit}>
          {!isEditing && (
            <p className='mx-auto text-xl text-white font-bold text-center truncate ellipisis'>
              {editable && <img src={penIcon} className='my-2 mr-3 text-white w-3 h-3 float-left' />}
              {title}
            </p>
          )}
          {isEditing && (
            <>
              <button className='flex text-white font-bold mx-4' onClick={handleSave}>✓</button>

              <input
                ref={inputRef}
                className='flex flex-1 bg-primary text-xl text-white font-bold text-center truncate ellipisis'
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}

                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  }
                }}

              />
              <button className='flex text-white font-bold mx-4' onClick={handleCancel}>X</button>
            </>
          )}
        </div>

        <div className='flex space-x-2 w-16 justify-center'>
          {hasTwitter && <TwitterButton />}
          {hasDiscord && <DiscordButton />}
          {hasShare && <ShareButton onShare={onShare} title={shareTitle} description={shareDescription} />}
        </div>
      </div>
    </div>
  );
};

export default Header;