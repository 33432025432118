
import React from 'react';
import { isMobile } from 'react-device-detect';

const Question = ({ title }: { title: string }) => {
  const length = title.length;
  const isSmallerThan60 = length <= 60 && isMobile;
  const isBetween60And90 = length > 60 && length <= 90 && isMobile;

  return (
    <div className={`text-white font-bold text-center text-vw-xl sm:text-xl xl:text-3xl ${isMobile && 'se:text-vw-lg'} px-3 sm:px-0 flex-1 flex items-center justify-center select-none ${isSmallerThan60 && 'text-vw-3xl se:text-vw-2xl'} ${isBetween60And90 && 'text-vw-2xl se:text-vw-xl'}`}>
      <span>{title}</span>
    </div>
  )
}

export default Question;