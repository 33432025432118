import React from 'react';
import PlayersCard from './PlayersCard';
import TrophyIcon from '../../../assets/svgs/cup-icon.svg';
import HomeIcon from '../../../assets/svgs/home.svg';
import CloseIcon from '../../../assets/svgs/x.svg';
import CryIcon from '../../../assets/svgs/cry.svg';
import { IUser } from '../../../types/api';
import { selectFirstName } from '../../../helpers/helpers';
import RewardText from '../../MatchResults/components/RewardText';
import { useNavigate, useParams } from 'react-router-dom';

interface IMatchResultWithOpponent {
  user: IUser;
  opponent: IUser;
  userIsWinner: boolean;
  isDraw: boolean;
  points: number;
}
const MatchResultWithOpponent = (props: IMatchResultWithOpponent) => {
  const { user, userIsWinner, opponent, isDraw, points } = props;
  const navigate = useNavigate();
  const { slug } = useParams();

  return (
    <div className={`w-full text-center relative`}>
      {!userIsWinner ? (
        <div
          className={'w-full h-full absolute sm:-top-[5%] -top-[2%] sm:left-[4%] left-[0.5%] mx-0'}
        >
          <img src={TrophyIcon} alt={'logo'} />
        </div>
      ) : (
        <img
          src={CryIcon}
          alt={'logo'}
          className={'absolute top-[11%]'}
          style={{ left: 'calc(50% - 52px)' }}
        />
      )}
      <div className="flex justify-center relative mt-60 mb-16">
        <PlayersCard player={user} isWinner={!isDraw && userIsWinner} isUser={true} />
        <div className="mx-1.5" />
        <div className={'absolute z-10 text-white text-bold text-[32px] -top-20'}>
          {userIsWinner ? 'You won!' : 'Try again!'}
        </div>
        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 z-10 text-white font-bold text-lg bg-slate-700 p-2 rounded-full justify-center self-center">
          VS
        </span>
        <PlayersCard player={opponent} isWinner={!isDraw && !userIsWinner} />
      </div>
      <RewardText
        opponentName={selectFirstName(opponent?.display_name)}
        isDraw={isDraw}
        userIsWinner={userIsWinner}
        points={points}
      />
      <div className="z-10 w-full flex justify-center items-center absolute top-[86vh]">
        <img src={HomeIcon} onClick={() => navigate(`/events`)} className={'cursor-pointer'} />
        <div className={'w-72 px-6'}>
          <div
            onClick={() => navigate(`/events/${slug}/matching`)}
            className={`flex flex-1 text-xl text-white justify-center items-center bg-pink-500 h-14 text-white font-bold rounded-xl cursor-pointer active:bg-pink-600 transition duration-300 ease-in-out hover:opacity-90`}
          >
            Next challenger
          </div>
        </div>
        <img
          src={CloseIcon}
          onClick={() => navigate(`/events/${slug}`)}
          className={'cursor-pointer'}
        />
      </div>
    </div>
  );
};

export default MatchResultWithOpponent;
