import React from "react"
import Header from "./Header"

export interface HeaderProps {
  title?: string;
  hasBack?: boolean;
  backLink?: string;
  hasShare?: boolean;
  hasDiscord?: boolean;
  hasTwitter?: boolean;
  shareTitle?: string;
  shareDescription?: string;
  onShare?: () => void;
  editable?: boolean;
  onEdit?: (value: string) => void;
}

export default function HeaderWrapper(props: HeaderProps) {
  return <Header {...props} />
}