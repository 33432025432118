import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { CookiesProvider } from 'react-cookie';
import { UserProvider } from './contexts/UserContext';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import './analytics/firebase';
import router from './routes';
import {
  RouterProvider,
} from "react-router-dom";
import { SheetProvider } from './contexts/SheetContext/SheetContext';
import { OrientationProvider } from './contexts/OrientationContext/OrientationContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Toaster } from 'react-hot-toast';
import TagManager from 'react-gtm-module';

export const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const tagManagerArgs = {
  gtmId: 'GTM-54VFMDP'
}

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  TagManager.initialize(tagManagerArgs);
}

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <UserProvider>
            <SheetProvider>
              <OrientationProvider>
                <Toaster />
                <RouterProvider router={router} />
              </OrientationProvider>
            </SheetProvider>
          </UserProvider>
        </CookiesProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

