import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { IEvent } from './../../../types/api';
import { formatTimeLeft } from './../../../helpers/helpers';

function EventCard({ event }: { event: IEvent }) {
  const { name, slug, banner_image_url, seconds_left, metadata } = event;

  const status = useMemo(() => {
    if (metadata) {
      const { total_challenges: total, total_challenges_played: played } = metadata
      return `Completed : ${played}/${total}`
    }
    return formatTimeLeft(seconds_left);
  }, [seconds_left, metadata]);

  return (
    <div className='flex flex-col h-52 bg-white w-40 min-w-40 rounded-xl p-4 drop-shadow-custom'>
      <p title={name} className='text-center font-bold text-indigo-1000 truncate hover:text-blue-800 transition-all duration-300 ease-in-out transform hover:scale-105'>{name}</p>
      <p className='text-center text-sm truncate text-indigo-1000'>{status}</p>

      <div className='flex flex-1 items-center'>
        <div className='h-18 w-full bg-white rounded-xl'>
          <img src={banner_image_url} className='h-18 w-full object-cover rounded-xl' />
        </div>
      </div>

      <Link to={`/events/${slug}`}>
        <div className='flex justify-center items-center bg-pink-500 h-8 text-white font-bold rounded-xl cursor-pointer active:bg-pink-600'>
          <p className='text-sm'>View</p>
        </div>
      </Link>
    </div>
  )
}

export default EventCard;