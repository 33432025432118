import React from 'react';
import { useParams } from 'react-router-dom';
import eventHandler from '../../services/events/show';
import Page from '../../components/organisms/Page';
import { LoadingSpinner } from '../../components/molecules';
import MatchReward from './MatchReward';

function EventWrapper() {
  const params = useParams();
  const response = eventHandler({ params });

  if (response.status === 'success')
    return (
      <Page className="bg-black" hasOpacityTransition>
        <MatchReward />
      </Page>
    );

  if (response.status === 'loading')
    return (
      <Page className="bg-black">
        <LoadingSpinner show />
      </Page>
    );

  return null;
}

export default EventWrapper;
