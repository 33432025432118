import React from 'react';

function RoundedLoginButton({ handleOnClick }: { handleOnClick: React.MouseEventHandler<HTMLDivElement> }) {
  return (
    <div className='flex w-36 h-36 bg-gray-300 rounded-full mx-auto justify-center items-center border-4 border-gray-400 cursor-pointer active:bg-gray-200' onClick={handleOnClick}>
      <p className='text-primary font-bold text-xl'>
        Login
      </p>
    </div>
  )
}

export default RoundedLoginButton;