import React from 'react';

import Matching from './Matching';
import Page from '../../components/organisms/Page';

function MatchWrapper() {
  return (
    <Page className='bg-primary'>
      <Matching />
    </Page>
  )
}

export default MatchWrapper;