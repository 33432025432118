import { ShareButton } from '../../../components/organisms/Header/Header';
import Leaderboard from '../../Leaderboard/components/Leaderboard';
import React from 'react';

interface IMatchResultSinglePlayer {
  your_score: number;
  handleOnClick: () => void;
  eventResponse: Record<string, any>;
  topPlayersResponse: Record<string, any>;
  response: Record<string, any>;
  handleOnFinish: () => void;
}
const MatchResultSinglePlayer = (props: IMatchResultSinglePlayer) => {
  const { your_score, handleOnClick, eventResponse, topPlayersResponse, response, handleOnFinish } =
    props;
  return (
    <>
      <div className="flex flex-col items-center space-y-3 z-10">
        <p className="text-white text-5xl font-bold">Well done!</p>
        <div className="flex items-center text-yellow-400 text-5xl font-bold">
          <div className="flex items-center">
            <p>{your_score}</p>
            <p className="text-xl">pts</p>
          </div>
          <ShareButton
            title={eventResponse.data?.name}
            description={`I just scored big on ${eventResponse.data?.category?.title} : ${eventResponse.data?.name}! Can you best it?`}
          />
        </div>
        <p className="text-xs text-indigo-200">Correct answers + Time bonus + Completion bonus</p>
        <p className="text-xl text-indigo-200 font-bold text-center">{`${eventResponse.data?.category?.title} : ${eventResponse.data?.name} Quiz #${response.data?.challenge_position}`}</p>
      </div>

      <Leaderboard players={topPlayersResponse.data} status={topPlayersResponse.status} />

      <div className="flex flex-1 z-10" />

      <div className="flex w-full space-x-1 justify-center z-50 h-40">
        <div className="w-1/3 z-50">
          <div
            onClick={handleOnFinish}
            className="z-50 flex justify-center items-center bg-indigo-200 h-14 text-indigo-900 font-bold rounded-xl cursor-pointer active:bg-indigo-300 w-full"
          >
            <p className="text-xl">Finish</p>
          </div>
        </div>
        <div className="z-50 w-2/4">
          <div
            onClick={handleOnClick}
            className="z-50 flex justify-center items-center bg-pink-500 h-14 text-white font-bold rounded-xl cursor-pointer active:bg-pink-600 w-full"
          >
            <p className="text-xl">Continue</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchResultSinglePlayer;
