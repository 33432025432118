import { useQuery } from "react-query";

import api from "./../../config/api";
import { useUser } from "./../../contexts/UserContext";

interface Response {
  challenge_id?: string;
  is_playable: boolean;
  total_challenges_played?: number;
  total_challenges?: number;
  error?: string;
}

export default function handler({params}) {
  const { user } = useUser();

  const response = useQuery<Response>(['eventAvailability', user?.id, params.slug], async () => {
    const response = await api.checkEventAvailability({params});
    return response.data
  })
  
  return response;
}