import React from 'react';
import { useParams } from 'react-router-dom';

import MatchResult from './MatchResult';
import eventHandler from './../../services/events/show';

import Page from '../../components/organisms/Page';
import { LoadingSpinner } from './../../components/molecules';

function EventWrapper() {
  const params = useParams();
  const response = eventHandler({ params })

  if (response.status === 'success')
    return (
      <Page className='bg-primary' hasOpacityTransition>
        <MatchResult />
      </Page>
    )

  if (response.status === 'loading')
    return <Page className='bg-primary'>
      <LoadingSpinner show />
    </Page>

  return null
}

export default EventWrapper;