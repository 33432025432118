import { IUser } from '../../../types/api';
import blankProfileImage from '../../../assets/images/blank_profile_picture.png';
import CrownIcon from '../../../assets/svgs/crown-icon.svg';
import React from 'react';
import { selectFirstName } from '../../../helpers/helpers';

const PlayersCard = ({
  player,
  isWinner,
  isUser,
}: {
  player?: IUser;
  isWinner: boolean;
  isUser?: boolean;
}) => {
  return (
    <div
      className={`h-60 w-44 bg-stone-600 rounded-3xl items-center flex flex-col justify-center bg-opacity-80 ${
        isWinner && 'border-4 border-amber-300 relative'
      }`}
    >
      {isWinner && <img src={CrownIcon} alt={'logo'} className={'absolute top-0 -z-2'} />}

      <div
        className={`flex-shrink-0 ${isWinner || isUser ? 'bg-amber-300' : 'bg-stone-400'}`}
        style={{
          borderRadius: 80,
          clipPath: 'polygon(-4% 50%, 25% 0%, 76% 0%, 104% 50%, 75% 100%, 25% 100%)',
          padding: 2,
        }}
      >
        <img
          style={{ clipPath: 'polygon(-4% 50%, 25% 0%, 76% 0%, 104% 50%, 75% 100%, 25% 100%)' }}
          className="w-24 h-24 object-cover rounded-full text-center"
          src={player?.profile_image_url || blankProfileImage}
        />
      </div>
      <div className={'pt-4 font-bold text-white'}>{selectFirstName(player?.display_name)}</div>
    </div>
  );
};

export default PlayersCard;
