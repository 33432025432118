

import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import { Header } from './../../components/organisms';
import ProfileSection from './components/ProfileSection';
import EventsPerCategory, { Response } from './components/EventsPerCategory';

import { IUser, ReactQuery } from './../../types/api';

import halfCenterCircle from './../../assets/images/half_center_circle.png';

import api from './../../config/api';
import { queryClient } from './../../index';

function PullToRefresh({ children }) {
  const screenHeight = window.screen.height || window.innerHeight;
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState(0);
  const refreshCont = useRef(null);

  const initLoading = () => {
    refreshCont.current.classList.add("loading");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const pullStart = (e) => {
    const { screenY, pageY } = e.targetTouches[0];

    const isTop = pageY <= (screenHeight * 0.30)
    if (isTop) {
      setStartPoint(screenY);
    }
  };

  const pull = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    const pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
    const isTop = startPoint <= (screenHeight * 0.30)

    if (isTop && startPoint !== 0) {
      setPullChange(pullLength);
    }
  };

  const endPull = (e) => {
    setStartPoint(0);
    setPullChange(0);
    if (pullChange && pullChange > 220) initLoading();
  };

  // add and remove event listeners
  useEffect(() => {
    window.addEventListener("touchstart", pullStart);
    window.addEventListener("touchmove", pull);
    window.addEventListener("touchend", endPull);
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });

  return <>
    <div
      ref={refreshCont}
      className="flex flex-1"
      style={{ marginTop: (Math.min(pullChange / 5, 30) - 64) || "" }}
    >
      <div className={`p-2 rounded-full absolute transform -translate-x-1/2 left-1/2 z-20 opacity-0 transition-opacity duration-1000 ${pullChange && 'opacity-100'}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#FFF"
          className="w-6 h-6"
          style={{ transform: `rotate(${pullChange}deg)` }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>
      </div>
    </div>
    <div className="flex flex-1 flex-col mt-16">
      {children}
    </div>
  </>
}

function Events({ categories, user }: ReactQuery & { categories: Response[]; user: IUser }) {

  async function handleOnEdit(value: string) {
    value = value.trim();

    if (value.length < 3 || value.length > 20) {
      toast.error('Display name must be between 3 and 20 characters');
      return;
    }

    try {
      await api.updateMe({ params: { display_name: value } });
      await queryClient.invalidateQueries('currentUser');

      toast.success('Your display name was updated')
    } catch (err) {
      console.warn(err.message);
    }
  }

  return (
    <div className='flex flex-1 flex-col bg-primary overflow-y-auto no-scrollbar relative'>
      <img src={halfCenterCircle} className='-top-10 absolute lg:hidden object-cover w-full max-w-md' />
      <Header
        editable={!!user}
        onEdit={handleOnEdit}
        title={user ? user.display_name : 'Beta Version'}
        hasTwitter
        hasDiscord
      />
      <PullToRefresh>

        <ProfileSection />

        <EventsPerCategory categories={categories} status={'success'} />
      </PullToRefresh>
      <div />
    </div>
  );
}

export default Events;