import React from 'react';

import NotFound from './NotFound';
import Page from '../../components/organisms/Page';

function NotFoundWrapper() {
  return (
    <Page className='bg-primary'>
      <NotFound />
    </Page>
  )
}

export default NotFoundWrapper;