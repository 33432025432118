import React, { useEffect, memo } from 'react';
import { CTimer } from '../../../components/organisms/CTimer';

interface ITimerCountDown {
  initCountDown?: boolean;
  defaultTime?: number;
}

const TimerCountDown = memo((props: ITimerCountDown) => {
  const { initCountDown, defaultTime } = props;
  const { svg, animateTo } = CTimer({ size: 38, angle: 2 });

  //call animateTo if cTime is true
  useEffect(() => {
    if (initCountDown) animateTo(360, (defaultTime - 5) * 1000);
  }, [initCountDown]);

  return (
    <div className="flex flex-col text-center mt-5">
      <p style={{ color: '#4CCFFE' }} className={'text-xs'}>
        Time Left
      </p>
      <div className={'mt-3 -ml-1.5'}>{svg}</div>
    </div>
  );
});

export default TimerCountDown;
