import React from 'react';
import blankProfileImage from './../../../assets/images/blank_profile_picture.png';
import { IUser } from '../../../types/api';
import { useSheet } from './../../../contexts/SheetContext/SheetContext';

function Leaderboard({ players, status, extraContainerClasses }: { players?: any; status?: any; extraContainerClasses?: string; }) {
  const { setSheet, setData } = useSheet();

  if (status !== 'success')
    return null

  return <div className='contents px-4 w-full se:px-0 z-10 relative'>
    <div className={`${extraContainerClasses} z-10 h-full bg-indigo-200 mx-auto w-11/12 rounded-3xl mb-8 p-4 mt-2 border-8 overflow-y-scroll no-scrollbar z-10`} style={{ borderColor: '#425298' }}>
      <table className='text-indigo-900 w-full'>
        <thead>
          <tr className='font-semibold text-xl'>
            <th className='w-2/4 text-left'>Player</th>
            <th className='w-1/4'>Rank</th>
            <th className='w-1/4'>Score</th>
          </tr>
        </thead>
        <tbody className='align-baseline z-10 relative'>
          {players.map((player: IUser, index) => (
            <tr key={`${index}-leaderboard-result`} className='cursor-pointer align-middle h-20 my-auto z-50 relative' onClick={() => {
              setData({ userId: player.id })
              setSheet('user-statistics');
            }}>
              <td className='h-20 w-2/4'>
                <div className='h-20 flex items-center'>
                  <div className='flex-shrink-0 bg-white' style={{
                    width: 50,
                    height: 50,
                    borderRadius: 80,
                    clipPath: "polygon(-4% 50%, 25% 0%, 76% 0%, 104% 50%, 75% 100%, 25% 100%)",
                    padding: 3,
                  }}>
                    <img style={{
                      width: 44,
                      height: 44,
                      borderRadius: 80,
                      clipPath: "polygon(-4% 50%, 25% 0%, 76% 0%, 104% 50%, 75% 100%, 25% 100%)",
                      alignSelf: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                      referrerPolicy='no-referrer'
                      src={player?.profile_image_url ? player?.profile_image_url : blankProfileImage}
                    />
                  </div>
                  <p className='mt-12 self-center h-20 ml-4 font-bold text-xl truncate w-24'>{player.display_name}</p>
                </div>
              </td>
              <td className='text-3xl font-bold text-center w-1/4'>{index + 1}</td>
              <td className='text-3xl font-bold text-center w-1/4'>{player.total_score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
}

export default Leaderboard;