import React, { useEffect } from 'react';

import { Outlet, createBrowserRouter, useNavigate, useRouteError } from 'react-router-dom';

import CountdownScreen from './shared/screens/Countdown';
import api from './config/api';

import { saveTokenLocally } from './helpers/helpers';

import { useUser } from './contexts/UserContext';

import {
  Event,
  Events,
  PrivacyPolicy,
  UserAgreement,
  MatchResults,
  NotFound,
  Leaderboard,
  GlobalLeaderboard,
  Match,
  Matching,
  MatchRewards,
} from './pages';

function Redirect() {
  const navigate = useNavigate();
  const error = useRouteError();
  const { setToken } = useUser();

  useEffect(() => {
    if (error || document.location.pathname === '/') {
      const params = new URL(document.location.href).searchParams;
      const code = params.get('code');
      const state = params.get('state');

      if (code && state) {
        api.loginAuthCallback({
          params: {
            code,
            state,
            provider: 'tiktok',
            location: document.location.pathname,
          },
          onSuccess: (response) => {
            saveTokenLocally(response.data.token);
            setToken(response.data.token);

            // Push the event to dataLayer
            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({ 'event': 'Signup' });

            navigate('events');
          },
          onError: (response) => {
            console.log(response);
            navigate('events');
          },
        });
      } else {
        navigate('events');
      }
    }
  }, [error]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      const script = document.createElement('script');
      script.text = `
      !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_m9o3d311', {"optOut":false,"useDecimalCurrencyValues":true});rdt('track', 'PageVisit');

      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

        ttq.load('CHMH2IRC77U4209UQ2A0');
        ttq.page();
      }(window, document, 'ttq');
      `;
      document.body.appendChild(script);
    }

  }, []);

  return <Outlet />;
}

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <NotFound />,
    element: <Redirect />,
    children: [
      {
        path: '',
        element: <Outlet />,
        errorElement: <NotFound />,
        children: [
          {
            path: 'privacy-policy',
            element: <PrivacyPolicy />,
          },
          {
            path: 'user-agreement',
            element: <UserAgreement />,
          },
          {
            path: 'events',
            element: <Events />,
          },
          {
            path: 'events/:slug',
            element: <Event />,
          },
          {
            path: 'leaderboard',
            element: <GlobalLeaderboard />,
          },
          {
            path: 'events/:slug/leaderboard',
            element: <Leaderboard />,
          },
          {
            path: 'events/:slug/challenges/:challengeId/play',
            element: <CountdownScreen />,
          },
          {
            path: 'events/:slug/challenges/:challengeId/match',
            element: <Match />,
          },
          {
            path: 'events/:slug/matching',
            element: <Matching />,
          },
          {
            path: 'events/:slug/challenges/:challengeId/result',
            element: <MatchResults />,
          },
          {
            path: 'events/:slug/challenges/:challengeId/reward',
            element: <MatchRewards />,
          },
          {
            path: 'challenges/:challengeId/countdown',
            element: <CountdownScreen />,
          },
        ],
      },
    ],
  },
]);

export default router;
