import instance from './axios';

const defaultOnError = (error: any) => {
  console.warn({ error });
};

interface ApiRequestBody {
  params?: any;
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

export interface PlayParams {
  params: {
    a_id?: 'b0d9abdb-1959-4d52-8aa9-a095182df9a5';
    c_id?: '37e90e6f-872e-4e64-bb7e-88a81caa5732';
  };
}

const api = {
  login: ({ params, onSuccess, onError = defaultOnError }: ApiRequestBody) =>
    instance.post('/login', params).then(onSuccess).catch(onError),
  fetchMe: () => instance.get('/users/me'),
  updateMe: ({ params }) => instance.put('/users/me', params),
  fetchRankingLogs: ({ params }: ApiRequestBody) => instance.get('/ranking_logs', { params }),
  fetchChallenges: ({ params }: ApiRequestBody) => instance.get('/challenges', { params }),
  fetchEvents: ({ params }: ApiRequestBody) => instance.get('/events', { params }),
  fetchCategoriesWithEvents: ({ params }: ApiRequestBody) =>
    instance.get('/categories_with_events', { params }),
  fetchEvent: ({ params }: ApiRequestBody) => instance.get(`/events/${params.slug}`),
  checkEventAvailability: ({ params }: ApiRequestBody) =>
    instance.get(`/event_availability/${params.slug}`),
  challengeResults: ({ params }: ApiRequestBody) => instance.get(`/challenge_results/${params.id}`),
  fetchRankingMe: ({ params }: ApiRequestBody) => instance.get('/rankings/me', { params }),
  fetchRanking: ({ params }: ApiRequestBody) => instance.get('/rankings', { params }),
  fetchChallenge: ({ params, onSuccess, onError = defaultOnError }: ApiRequestBody) => {
    const { params: requestParams, ...rest } = params;
    const id = requestParams.id;

    instance
      .get(`/challenges/${id}`, { ...rest, ...requestParams })
      .then(onSuccess)
      .catch(onError);
  },
  loginAuthCallback: ({ params, onSuccess, onError = defaultOnError }: ApiRequestBody) =>
    instance.get(`/auth/${params.provider}/callback`, { params }).then(onSuccess).catch(onError),
  play: ({ params, onSuccess, onError = defaultOnError }: ApiRequestBody & any) =>
    instance.post('/trivia', params).then(onSuccess).catch(onError),
  createAuthCallback: ({ params, onSuccess, onError = defaultOnError }: ApiRequestBody) =>
    instance.post('/auth/auth_token', { params }).then(onSuccess).catch(onError),
  createTikTokAuthCallback: ({ params, onSuccess, onError = defaultOnError }: ApiRequestBody) =>
    instance.post('/auth/auth_token/tiktok', { params }).then(onSuccess).catch(onError),
  createMatching: ({ params }: ApiRequestBody) => instance.post('/matching', params),
  createUser: ({ params, onSuccess, onError = defaultOnError }: ApiRequestBody) =>
    instance.post('/users', params).then(onSuccess).catch(onError),
  fetchChallengerReward: ({ params }: ApiRequestBody) =>
    instance.get(`challenges/${params}/reward`),
};

export default api;
