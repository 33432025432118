import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import ogmiLogo from './../../assets/svgs/logos/ogmi.svg';
import { ChallengerCard } from './components';
import useMatching from './hooks';

export default function Matching() {
  const { user } = useUser();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { opponent, challenge, startChallenge } = useMatching({ slug });

  const eventName = challenge?.event?.name;
  const categoryName = challenge?.category?.title;

  return (
    <div className="w-full text-center flex flex-col py-20">
      <div className="w-16 mx-auto">
        <img src={ogmiLogo} />
      </div>

      <p className="font-bold text-gray-400 mt-2">Quiz topic</p>

      {eventName && <div className="text-white text-3xl font-bold space-x-2 text-center">
        <span>{eventName}</span>
        <span>x</span>
        <span>{categoryName}</span>
      </div>}

      <div className="flex flex-row justify-center mt-6 relative">
        <ChallengerCard user={user} />

        <div className="mx-2" />

        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 z-10 text-white font-bold text-lg bg-indigo-1100 p-2 rounded-full justify-center self-center">
          VS
        </span>
        <ChallengerCard user={opponent} isOpponent={true} />
      </div>

      <div className="flex flex-1">
        <div
          onClick={() =>
            startChallenge && navigate(`/events/${slug}/challenges/${challenge?.id}/play`)
          }
          className={`mt-auto mx-2 flex-1 flex justify-center items-center bg-pink-500 h-14 text-white font-bold rounded-xl cursor-pointer active:bg-pink-600 transition duration-300 ease-in-out hover:opacity-90`}
        >
          <p className={`text-xl ${startChallenge ? 'text-gray-50' : 'text-pink-700'}`}>
            {startChallenge ? 'Start challenge' : 'Waiting...'}
          </p>
        </div>
      </div>
    </div>
  );
}
