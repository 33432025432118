import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { queryClient } from '../../index';
import challengeResultsHandler from './../../services/challengeResults/show';
import rankingLogsHandler from './../../services/rankingLogs';
import eventHandler from './../../services/events/show';
import usePageMeta from './../../hooks/usePageMeta';

import fireworksGif from './../../assets/gifs/fireworks.gif';
import segment from './../../analytics/segment';
import MatchResultSinglePlayer from './components/MatchResultSinglePlayer';

export default function MatchResult() {
  usePageMeta({ themeColor: '#182C82' });
  useEffect(() => {
    const element = document.querySelector('.block-swipe-nav');
    const handleTouchStart = (e) => {
      if (e.pageX) {
        if (e.pageX > 10 && e.pageX < window.innerWidth - 10) {
          return;
        }
        e.preventDefault();
      }
    };
    if (element) element.addEventListener('touchstart', handleTouchStart);

    return () => {
      if (element) element.removeEventListener('touchstart', handleTouchStart);
    };
  }, [document.querySelector('.block-swipe-nav')]);

  const navigate = useNavigate();
  const { challengeId, slug } = useParams();

  const response = challengeResultsHandler({ params: { id: challengeId } });
  const topPlayersResponse = rankingLogsHandler({ params: { challenge_id: challengeId } });
  const eventResponse = eventHandler({ params: { slug } });

  if (response.status !== 'success') return null;

  const your_score = response.data.your_score;

  const handleOnClick = async () => {
    await Promise.all([queryClient.invalidateQueries('currentUser')]);
    segment.track('quiz_continue_button_clicked', {
      event_name: slug,
      quiz_number: challengeId,
    });
    navigate(`/events/${slug}`);
  };

  const handleOnFinish = () => {
    segment.track('quiz_finish_button_clicked', {
      event_name: slug,
      quiz_number: challengeId,
    });
    navigate('/events');
  };

  return (
    <div className="block-swipe-nav flex flex-1 flex-col overflow-y-auto min-h-full h-full justify-between items-center py-6 relative z-50">
      <MatchResultSinglePlayer
        your_score={your_score}
        handleOnClick={handleOnClick}
        eventResponse={eventResponse}
        response={response}
        topPlayersResponse={topPlayersResponse}
        handleOnFinish={handleOnFinish}
      />
      <img src={fireworksGif} className="absolute top-0 left-0 z-0 opacity-30 z-0" />
      <img src={fireworksGif} className="absolute bottom-0 right-0 z-0 opacity-30 z-0" />
    </div>
  );
}
